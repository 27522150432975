<template>
  <div
    class="industryRisk"
    v-loading.fullscreen.lock="
      hotLoading | keywordLoading | typeLoading | polarityLoading | newsLoading
    "
  >
    <!-- <div class="newsEchartsBox" v-show="isFirst">
      <div class="industryBox">
        <div class="industryBox-item" v-for="i in imgList" :key="i.url" @click="handleClickIndustry(i.text)">
          <img :src="i.url">
          <div class="industryBox-text">{{ i.text }}</div>
        </div>
      </div>
    </div> -->
    <div class="newsEchartsBox" v-show="isFirst">
      <el-tabs v-model="tabsValue" @tab-click="handleTabClick" style="width:100%">
        <el-tab-pane class="newsEchartsBox" label="分类统计" name="1">
          <news-industry-bar :apiDataBarChart="newsTypeData" style="width: 70%"></news-industry-bar>
          <news-keyword-cloud
          style="margin-left: 0px"
            :apiDataLineChart="wordCloudData"
          ></news-keyword-cloud>
        </el-tab-pane>
        <el-tab-pane class="newsEchartsBox" label="趋势统计" name="2">
         <news-echarts-risk :apiDataLineChart="newsEchartsDataTotal"></news-echarts-risk>
          <news-keyword-cloud
          style="margin-left: 0px"
            :apiDataLineChart="wordCloudData"
          ></news-keyword-cloud>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="newsEchartsBox" v-show="!isFirst">
      <news-echarts-risk :apiDataLineChart="newsEchartsData"></news-echarts-risk>
    </div>
    <div class="industryRisk-left">
      <news-list-risk :apiDataNewsList="newsList" :newsTotal="newsTotal" :hasKeyword="false" @page="handlePageChange" :industry="riskNewsParams.industry"></news-list-risk>
      <!-- <div class="showMore" @click="showMore">
        <p>{{ showMoreText }}</p>
      </div> -->
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js"
import { EventBus } from "../utils/eventBus.js";
import NewsListRisk from "@/components/NewsListRisk.vue";
import EventList from "@/components/EventList.vue";
import PolarityPie from "@/components/PolarityPie.vue";
import NewsIndustryBar from "@/components/NewsIndustryBar.vue";
import NewsKeywordCloud from "@/components/NewsKeywordCloud.vue";
import NewsEchartsRisk from "@/components/NewsEchartsRisk.vue";

export default {
  components: {
    NewsListRisk,
    EventList,
    PolarityPie,
    NewsIndustryBar,
    NewsKeywordCloud,
    NewsEchartsRisk,
  },
  data() {
    return {
      tabsValue: "1",
      isFirst: true,
      newsTotal: 0,
      newsEchartsData: {},
      newsEchartsDataTotal: {},
      wordCloudData: [],
      riskNewsParams: {
        page: 1,
        page_size: 10,
        industry: "",
        risk_flag: "",
        region: ""
      },
      imgList: [
        {
          text: "新能源汽车",
          url: require("@/assets/images/xnyqc.png")
        },
        {
          text: "生物医药",
          url: require("@/assets/images/swyy.png")
        },
        {
          text: "医疗设备",
          url: require("@/assets/images/ylsb.jpg")
        },
        {
          text: "工业互联网",
          url: require("@/assets/images/gyhlw.png")
        },
        {
          text: "钢铁",
          url: require("@/assets/images/gt.png")
        },
        {
          text: "农用机械",
          url: require("@/assets/images/nyjx.png")
        },
        {
          text: "先进轨道交通",
          url: require("@/assets/images/xjgdjt.png")
        },
        {
          text: "能源",
          url: require("@/assets/images/ny.png")
        }
      ],
      loading: false,
      hotLoading: false,
      keywordLoading: false,
      typeLoading: false,
      polarityLoading: false,
      newsLoading: false,
      company_id: "",
      showMoreText: "加载更多",
      newsType: "",
      polarityData: [],
      date: "",
      newsTypeData: {
        legend: [],
        data: [],
      },
      newsParams: {
        page: 1,
        page_size: 10,
        company: "",
        permissions: "",
        news_type: "",
        polarity: "",
        industry: ""
      },
      newsList: [],
    };
  },
  methods: {
    // 获取产业风险舆情
    getIndustryRiskNews() {
      this.newsLoading = true
      api.getIndustryRiskNews(this.riskNewsParams).then(res => {
        console.log("产业风险舆情", res)
        this.newsList = res.data
        // res.data.forEach(r => {
        //   this.newsList.push(r)
        // })
        this.newsTotal = res.count
        this.newsLoading = false
      }).catch(err => {
        this.newsLoading = false
      })
    },
    // 获取产业风险关键词
    getNewsIndustryKeyword() {
      this.keywordLoading = true
      api.getNewsIndustryKeyword().then(res => {
        console.log("风险关键词", res)
        this.wordCloudData = res.data
        this.keywordLoading = false
      })
    },
    // 获取产业风险行业统计
    getNewsIndustryCount() {
      this.newsTypeData.legend = []
      this.newsTypeData.data = []
      this.typeLoading = true
      api.getNewsIndustryCount().then(res => {
        console.log(res)
        res.data.forEach(i => {
          this.newsTypeData.legend.push(i.name)
          this.newsTypeData.data.push(i.count)
          this.typeLoading = false
        })
        console.log("newsTypeData", this.newsTypeData)
      })
    },
    handleTabClick(val) {
      console.log(val)
      console.log("company_id", this.company_id)
      if (val.name == "2") {
        this.date = ""
        // this.getNewsHotCountIndustry()
        if (this.company_id == "") {
          this.date = this.getToday()
          console.log("date", this.date)
        }
        this.riskNewsParams.industry = ""
        this.getIndustryRiskCount(1)
        this.getNewsIndustryKeyword()
      } else if (val.name == "1") {
        this.getNewsIndustryKeyword()
        // this.getNewsIndustryCount()
      }
    },
    // 获取新闻热度统计(产业舆情)
    getNewsHotCountIndustry() {
      this.hotLoading = true;
      let params = {
        industry: this.newsParams.industry
      };
      api.getNewsHotCountIndustry(params).then((res) => {
        console.log("热度统计", res);
        this.newsEchartsData = res.data;
        this.hotLoading = false;
      }).catch(err => {
        this.hotLoading = false
      })
    },
    // 获取产业风险月度统计
    getIndustryRiskCount(flag) {
      this.hotLoading = true
      let params = {
        industry: this.riskNewsParams.industry
      }
      api.getIndustryRiskCount(params).then(res => {
        console.log("风险统计", res)
        if (flag == 0) {
          this.newsEchartsData = res.data
          this.isFirst = false
        } else {
          this.newsEchartsDataTotal = res.data
        }
        this.hotLoading = false
      }).catch(err => {
        this.hotLoading = false
      })
    },
    // 监听地区筛选
    listenRegion() {
      EventBus.$on("region", data => {
        this.riskNewsParams.region = data
        this.getIndustryRiskNews()
      })
    },
    // 监听源产业变化
    listenSourceIndustry() {
      EventBus.$on("choose_source_industry", data => {
        console.log("choose_source_industry", data)
        // this.newsList = [];
        // this.newsParams.industry = ""
        // this.newsParams.company = ""
        // this.newsParams.page = 1;
        // this.newsParams.polarity = "";
        // this.newsParams.news_type = "";
        this.newsList = []
        this.riskNewsParams.industry = data
        this.riskNewsParams.page = 1
        this.tabsValue = "1"
        this.getIndustryRiskNews()
        this.getIndustryRiskCount(0)
      })
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        console.log("监听重置")
        this.newsList = [];
        this.isFirst = true
        this.tabsValue = "1"
        this.riskNewsParams.industry = ""
        this.riskNewsParams.page = 1
        this.getIndustryRiskNews()
      });
    },
    // 监听风险筛选
    listenRiskRadio() {
      EventBus.$on("riskRadio", data => {
        this.riskNewsParams.risk_flag = data
        this.riskNewsParams.page = 1
        this.getIndustryRiskNews()
      })
    },
    // 监听删除
    listenRemove() {
      EventBus.$on("remove_news", (data) => {
        console.log("监听删除新闻")
        this.newsList = [];
        this.getNews();
      });
    },
    // 监听修改新闻极性
    listenChangePolarity() {
      EventBus.$on("change_polarity", (data) => {
        console.log("监听修改新闻极性")
        this.newsList = [];
        this.getNews();
      });
    },
    // 获取新闻极性统计(产业标签)
    getNewsPolarityCountIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions"),
        keyword: "",
        industry: this.newsParams.industry,
        news_type: this.newsParams.news_type
      };
      this.polarityLoading = true;
      api.getNewsPolarityCountIndustry(params).then((res) => {
        console.log("极性", res);
        this.polarityData = res.data;
        this.polarityLoading = false;
      });
    },
    // 换页
    handlePageChange(val) {
      console.log("换页")
      this.riskNewsParams.page = val;
      this.getIndustryRiskNews();
    },
    // 加载更多
    showMore() {
      this.riskNewsParams.page += 1;
      console.log("page", this.riskNewsParams.page);
      if (this.riskNewsParams.page <= 100) {
        this.getIndustryRiskNews();
      } else {
        this.showMoreText = "已无更多";
      }
    },
    // 获取新闻分类统计(产业标签)
    getNewsTypeCountIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions"),
        company_id: "",
        keyword: "",
        industry: this.newsParams.industry
      };
      this.newsTypeData.legend = [];
      this.newsTypeData.data = [];
      this.typeLoading = true;
      api.getNewsTypeCountIndustry(params).then((res) => {
        console.log("五大类", res);
        this.newsTypeData.legend = [];
        this.newsTypeData.data = [];
        res.data.forEach((i) => {
          this.newsTypeData.legend.push(i.name);
          this.newsTypeData.data.push(i.value);
        });
        console.log("xx", this.newsTypeData);
        this.typeLoading = false;
      });
    },
    // 获取当前年月
    getToday() {
      let myDate = new Date();
      let date = myDate.toLocaleDateString().split("/");
      // console.log("date", date)
      return `${date[0]}-${this.prefixZero(date[1], 2)}`;
    },
    // 前补0
    prefixZero(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    // 异步执行所有接口
    getAllData() {
      this.getNewsPolarityCountIndustry()
      this.getNews();
      this.getNewsTypeCountIndustry()
    }
  },
  mounted() {
    this.getIndustryRiskNews()
    // this.newsParams.permissions = sessionStorage.getItem("permissions");
    this.newsParams.permissions = cookie.getCookie("permissions")
    cookie.setCookie("serverValue", "产业风险")
    // this.date = this.getToday();
    this.date = ""
    // this.getAllData()
    this.getNewsIndustryKeyword()
    this.listenReset();
    // this.listenSourceIndustry()
    this.listenRemove();
    this.listenRiskRadio()
    this.listenRegion()
    this.getNewsIndustryCount()
  },
  beforeDestroy() {
    EventBus.$off("reset");
    EventBus.$off("remove_news")
    EventBus.$off("choose_source_industry")
    EventBus.$off("riskRadio")
    EventBus.$off("region")
  },
};
</script>

<style lang="scss">
.industryRisk {
  // margin-top: 52px;
  width: 100%;
  padding-bottom: 10px;
  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }
  &-right {
    margin-left: 5px;
  }
  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    margin-top: 4px;
    border-radius: 6px;
  }
}
</style>

<template>
  <div class="newsList" v-loading.fullscreen.lock="fullscreenLoading">
    <div
      class="title"
      style="justify-content: space-between; padding-right: 5px"
    >
    <div style="display: flex;align-items: center">
      <p id="zjxw">风险舆情</p>
      <el-radio-group style="margin-left: 15px" v-model="region" size="mini" @change="handleChangeRegion">
        <el-radio-button label="全部" ></el-radio-button>
        <el-radio-button label="大陆"></el-radio-button>
        <el-radio-button label="海外"></el-radio-button>
      </el-radio-group>
    </div>
      <div style="display: flex;align-items: center;">
        <el-radio-group v-model="riskRadio" @change="handleChangeRadio">
          <el-radio :label="9">全部</el-radio>
          <el-radio :label="0">无风险</el-radio>
          <el-radio :label="1">有风险</el-radio>
        </el-radio-group>
        <el-button type="primary" size="mini" style="margin-right: 15px;margin-left: 15px" icon="el-icon-document-copy" @click="handleExport">导出excel</el-button>
      </div>
    </div>
    <div class="newsItemCrew">
      <div
        class="newsListSourceItem"
        v-for="(i, index) in apiDataNewsList"
        :key="i.title + index.toString()"
        @click="showDetail(i)"
      >
        <div class="newsListSourceItem-name">
          <div class="newsTitle">
            <p
              v-if="hasKeyword"
              ref="newsTitleP"
              class="newsTitle-p"
              v-html="highlight(i.title, hasKeyword)"
            ></p>
            <p v-if="!hasKeyword" ref="newsTitleP" class="newsTitle-p">
              ▪ {{ i.title }}
            </p>
            <div style="display: flex; width: 5vw; margin-bottom: 5px">
              <p
                v-if="i.risk_flag !== ''"
                :class="
                  i.risk_flag == '0'
                    ? 'newsTitle-polarity'
                    : 'newsTitle-polarityBad'
                "
                style="padding: 0 5px 0 5px"
              >
                {{ i.risk_flag == "0" ? "无风险" : "有风险" }}
              </p>
            </div>
          </div>
        </div>
        <!-- <span class="newsListSourceItem-tag2" v-for="i in i.risk_industry" :key="i">{{ i }}</span> -->
        <div style="width: 70%;display: inline-block;padding-bottom: 5px;">
        <el-tag
          class="tagBox-tag"
          size="mini"
          v-for="(j, index) in i.risk_industry"
          :key="j.toString() + index"
          closable
          type="warning"
          @close="handleClose(j, 0, i)"
          >{{ j }}</el-tag
        >
        <!-- <span class="newsListSourceItem-tagBlue" v-for="c in i.risk_company" :key="c">{{c}}</span> -->
        <el-tag
          class="tagBox-tag"
          size="mini"
          v-for="j in i.risk_company"
          :key="j"
          closable
          type="primary"
          @close="handleClose(j, 1, i)"
          >{{ j }}</el-tag
        >
        <!-- <span class="newsListSourceItem-tag" v-for="i in i.risk_country" :key="i">{{ i }}</span> -->
        <el-tag
          class="tagBox-tag"
          size="mini"
          v-for="j in i.risk_country"
          :key="j"
          closable
          type="success"
          @close="handleClose(j, 2, i)"
          >{{ j }}</el-tag
        >
        <el-tag
          class="tagBox-tag"
          size="mini"
          v-for="j in i.risk_industry_rule"
          :key="j"
          closable
          type="danger"
          @close="handleClose(j, 3, i)"
          >{{ j }}</el-tag
        >
        <span class="newsListSourceItem-time">{{ i.release_time }}</span>
        <span class="newsListSourceItem-time">{{ i.source?i.source:i.web_source | webSourceFilter }}</span>
        </div>
        <el-button
          v-if="is_master == true"
          type="danger"
          size="mini"
          style="margin-left: 10px; margin-right: 10px; float: right"
          @click.stop="removeIndustryRiskNews(i.id, index)"
          >删除</el-button
        >
        <el-button
          v-if="is_master == true"
          type="warning"
          size="mini"
          style="margin-left: 10px; float: right"
          @click.stop="changeIndustryRiskNews(i)"
          >修改风险</el-button
        >
        <el-button
          v-if="is_master == true"
          type="primary"
          size="mini"
          style="margin-left: 0px; float: right"
          @click.stop="handleChangeIndustry(i)"
          >修改产业</el-button
        >
        <!-- <span v-if="i.source" class="newsListSourceItem-source">{{
          i.source == "" ? "未知" : i.source
        }}</span>
        <span v-else class="newsListSourceItem-source">{{ i.web_source }}</span> -->
        <!-- <span class="newsListSourceItem-tagBlue" v-if="i.event_classify">{{
          i.event_classify
        }}</span> -->
        <!-- <span v-for="(arr,idx) in i.title_classify_array" :key="arr +idx.toString()">
          <span class="newsListSourceItem-tagBlue" v-if="arr == 1">{{
            type_map[idx]
          }}</span>
        </span>
        <span v-for="(tag,index) in i.title_classify_tags" :key="tag+index.toString()">
          <span class="newsListSourceItem-tag2" v-if="index%2==0 && tag">{{
            tag
          }}</span>
          <span class="newsListSourceItem-tagRed" v-if="index%2!=0 && tag">{{
            tag
          }}</span>
        </span> -->
      </div>
    </div>
    <div style="display:flex;justify-content: center;margin: 10px 0 15px 0;">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="requestParams.page"
        :total="newsTotal"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
    <el-dialog
      class="newsDetail"
      title="新闻详情"
      :visible.sync="newsDetailVisible"
      width="60%"
      top="5vh"
      destroy-on-close
    >
      <div
        class="newsDetail-content"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        element-loading-target=".newsDetail"
      >
        <div class="newsDetail-content-header">
          <p class="newsDetailTitle">{{ newsDetail.title }}</p>
          <div class="des">
            <span class="time">{{ newsDetail.release_time }}</span>
            <span class="source">{{ newsDetail.source }}</span>
            <span class="source">{{ newsDetail.web_source }}</span>
          </div>
        </div>
        <div class="newsDetail-content-text" v-html="newsDetail.content"></div>
        <div class="btnBox">
          <el-button type="primary" @click="toOriginPage">前往原网页</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="修改产业" :visible.sync="showChangeIndustry" width="30%">
      <el-checkbox-group v-model="checkedIndustry">
        <el-checkbox v-for="industry in newsIndustryList" :label="industry.name" :key="industry.id">{{ industry.name }}</el-checkbox>
      </el-checkbox-group>
      <div style="display: flex;flex-direction: row-reverse">
        <el-button size="mini" type="primary" @click="changeNewsIndustry">确认修改</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导出excel" :visible.sync="showExportExcel" width="30%">
      <el-form :model="exportParams">
        <el-form-item label="产业">
          <el-select size="small" v-model="exportParams.industry_list" multiple placeholder="请选择产业">
            <el-option
              v-for="item in excelNewsIndustryList"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
      <el-date-picker
        value-format="yyyy-MM-dd"
        size="small"
        :unlink-panels="true"
        v-model="exportParams.excelDate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
        </el-form-item>
      <el-form-item label="数量">
    <el-input style="width: 70%" size="small" type="number" v-model="exportParams.news_count"  placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select size="small" v-model="exportParams.news_type" placeholder="请选择类型">
          <el-option
            v-for="item in countryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      </el-form>
      <div style="display: flex;flex-direction: row-reverse;margin-top: 20px">
        <el-button size="mini" type="primary" @click="exportExcel">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
import { EventBus } from "../utils/eventBus.js";
import cookie from "@/utils/common.js";

export default {
  name: "NewsList",
  props: {
    apiDataNewsList: Array,
    newsTotal: Number,
    industry: String,
    hasKeyword: {
      type: [String, Boolean],
    },
  },
  watch: {
    industry: {
      deep: true,
      handler(newVal,oldVal) {
        console.log("newVal", newVal)
        this.requestParams.page = 1
        console.log("this.requestParams.page", this.requestParams.page)
      }
    }
  },
  data() {
    return {
      region: "全部",
      fullscreenLoading: false,
      riskRadio: 9,
      countryOptions: [
        {
          label: "国内",
          value: "domestic"
        },
        {
          label: "海外",
          value: "overseas"
        }
      ],
      exportParams: {
        news_count: "100",
        news_type: "domestic",
        excelDate: "",
        industry_list: [],
        start_time: "",
        end_time: ""
      },
      permissions: "",
      excelNewsIndustryList: [],
      checkedIndustry: [],
      showExportExcel: false,
      newsType: "",
      newsIdSelected: 0,
      polarityType: "",
      is_master: false,
      newsDetailVisible: false,
      showChangeIndustry: false,
      newsIndustryList: [],
      // newsSearchCompany: "",
      requestParams: {
        page: 1,
        page_size: 10,
      },
      type_map: ["人员", "产品技术", "资金", "经营", "法律法规"],
      newsDetail: {
        title: "",
        release_time: "",
        source: "",
        content: "",
        url: "",
        web_source: "",
      },
      newsList: [],
    };
  },
  methods: {
    // 地区筛选
    handleChangeRegion(val) {
      if (val == "全部") {
        EventBus.$emit("region", "")
      } else if (val == "大陆") {
        EventBus.$emit("region", 0)
      } else if (val == "海外") {
        EventBus.$emit("region", 1)
      }
    },
    // 风险筛选
    handleChangeRadio(val) {
      console.log(val)
      if (val == 9) {
        EventBus.$emit("riskRadio", "")
      } else {
        EventBus.$emit("riskRadio", val)
      }
    },
    // 导出excel
    exportExcel() {
      console.log("excel参数", this.exportParams)
      this.exportParams.news_count = parseInt(this.exportParams.news_count)
      this.exportParams.start_time = this.exportParams.excelDate[0]
      this.exportParams.end_time = this.exportParams.excelDate[1]
      if (this.exportParams.industry_list.length == 0) {
        this.$message.warning("请选择产业！")
        return
      } else if (!this.exportParams.excelDate) {
        this.$message.warning("请选择日期！")
        return
      }
      this.fullscreenLoading = true
      api.exportIndustryRisk(this.exportParams).then(res => {
        console.log(res)
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel"
        })
        // let fileNameEncode = res.headers['content-disposition'].split("filename=")[1];
              // 解码
              // let fileName = decodeURIComponent(fileNameEncode, true)
              let fileName = "industry_risk.xls"
              // console.log("fileName",fileName)
              // if (window.navigator.msSaveOrOpenBlob) {
              //   // console.log(2)
              //   navigator.msSaveBlob(blob, fileName)
              // } else {
                // console.log(3)
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
                //释放内存
                window.URL.revokeObjectURL(link.href)
              // }
              this.fullscreenLoading = false
              this.showExportExcel = false
      }).catch(err => {
        this.fullscreenLoading = false
      })
    },
    // 点击导出excel
    handleExport() {
      this.showExportExcel = true
      this.exportParams = {
        news_count: "100",
        news_type: "domestic",
        excelDate: "",
        industry_list: [],
        start_time: "",
        end_time: ""
      }
    },
    // 切换页码
    handlePageChange(val) {
      console.log("page", val)
      this.$emit("page", val)
    },
    // 修改风险舆情产业
    changeNewsIndustry() {
      let params = {
        news_id: this.newsIdSelected,
        risk_industry: this.checkedIndustry
      }
      api.changeRiskNewsIndustry(params).then(res => {
        if (res.status == 200) {
          this.apiDataNewsList.forEach(n => {
            if (n.id == this.newsIdSelected) {
              n.risk_industry = this.checkedIndustry
              // console.log("修改风险舆情产业的新闻", n)
            }
          })
          this.showChangeIndustry = false
          this.$message.success(res.msg);
        }
      }).catch(err => {
        this.$message.error("修改失败，请重试！");
      })
      console.log("修改产业", params)
    },
    // 点击修改产业
    handleChangeIndustry(i) {
      console.log("点击修改产业", i)
      this.newsIdSelected = i.id
      this.checkedIndustry = i.risk_industry
      this.showChangeIndustry = true
    },
    // 获取新闻产业类型列表
    getNewsIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions")
      }
      api.getNewsIndustry(params).then(res => {
        console.log("获取新闻产业类型列表", res)
        this.newsIndustryList = res.data
        this.excelNewsIndustryList = res.data
      })
    },
    // 修改(删除)产业标签
    handleClose(value, type, news) {
      let tag_type_tmp = "";
      if (type == 0) {
        tag_type_tmp = "risk_industry";
      } else if (type == 1) {
        tag_type_tmp = "risk_company";
      } else if (type == 2) {
        tag_type_tmp = "risk_country";
      } else if (type == 3) {
        tag_type_tmp = "risk_industry_rule"
      }
      let params = {
        tag_type: tag_type_tmp,
        tag_value: value,
        news_id: news.id,
      };
      console.log("news", news)
      console.log("删除产业标签", params);
      this.$confirm("确定删除该标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.changeNewsTag(params).then((res) => {
            if (res.status == 200) {
              this.arrRemove(news[tag_type_tmp], value)
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          }).catch(err => {
            this.$message.error("删除失败，请重试！");
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 删除新闻
    removeIndustryRiskNews(id, index) {
      // console.log("title", title)
      let params = {
        news_id: id,
      };
      this.$confirm("此操作将删除该新闻, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("index", index);
          this.apiDataNewsList.splice(index, 1);
          api.removeIndustryRiskNews(params).then((res) => {
            if (res.status == 200) {
              this.$message.success("删除成功");
              // EventBus.$emit("remove_news", 1)
            } else {
              this.$message.warning(res.msg);
              // setTimeout(() => {
              //   EventBus.$emit("remove_news", 1)
              // }, 1000)
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改新闻极性
    changeIndustryRiskNews(i) {
      // 若新闻无极性，修改为正面
      let risk_flag = i.risk_flag ? (i.risk_flag == 1 ? 0 : 1) : 1;
      let params = {
        news_id: i.id,
        risk_flag: risk_flag,
      };
      console.log("i.risk_flag", i.risk_flag);
      this.$confirm(
        "您确定要修改该新闻的风险类型吗?（备注: 若该新闻无极性，修改为正面）",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          i.risk_flag = i.risk_flag == 1 ? 0 : 1;
          api.changeIndustryRiskNews(params).then((res) => {
            if (res.status == 200) {
              this.$message.success("修改成功");
              // EventBus.$emit("change_polarity", 1)
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 监听极性统计点击
    listenPolarity() {
      EventBus.$on("polarity", (data) => {
        this.polarityType = data;
      });
    },
    // 监听事件分类统计点击
    listenNewsFilter() {
      EventBus.$on("news_filter", (data) => {
        this.newsType = data;
      });
    },
    // 监听company_id改变
    listenCompanyId() {
      EventBus.$on("company_id", (data) => {
        this.newsType = "";
        this.polarityType = "";
      });
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        this.newsType = "";
        this.polarityType = "";
      });
    },
    // 重置筛选
    reset() {
      EventBus.$emit("resetSelect", 1);
      // EventBus.$emit("news_filter", "")
      this.newsType = "";
      this.polarityType = "";
    },
    // 前往原网页
    toOriginPage() {
      window.open(this.newsDetail.url, "_blank");
    },
    // 显示详情
    showDetail(info) {
      setInterval;
      if (info.content) {
        this.newsDetail = info;
        this.newsDetailVisible = true;
      } else {
        window.open(info.url, "_blank");
      }
    },
    // 获取某元素在数组中的下标
    getArrIndex(arr, val) {
      for(let i=0;i<arr.length;i++) {
        if (arr[i]==val) return i
      }
      return -1
    },
    // 删除数组的某元素
    arrRemove(arr, val) {
      let index = this.getArrIndex(arr, val)
      if (index > -1) {
        arr.splice(index, 1)
      }
    }
  },
  mounted() {
    this.permissions = cookie.getCookie("permissions")
    this.getNewsIndustry()
    this.listenNewsFilter();
    this.listenReset();
    this.listenCompanyId();
    this.listenPolarity();
    this.is_master =
      cookie.getCookie("module").indexOf("is_master") != -1 ? true : false;
    console.log("is_master", this.is_master);
  },
  beforeDestroy() {
    EventBus.$off("news_filter");
    EventBus.$off("polarity");
    EventBus.$off("company_id");
    EventBus.$off("reset");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.newsList {
  font-size: 17px;
  margin-top: 5px !important;
  // margin-left: 10px;
  width: 100%;
  border-radius: 7px;
  .searchParam {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #409eff;
    p {
      margin-right: 10px;
    }
  }
  .newsItemCrew {
    overflow-y: scroll;
    max-height: 70vh !important;
  }
  .newsListSourceItem {
    cursor: pointer;
    font-size: 16px;
    padding-left: 15px;
    margin-bottom: 10px;
    // padding-bottom: 10px;
    border-bottom: 1px solid #f5f4f9;
    // &-name {
    //   display: flex;
    //   justify-content: space-between;
    // }
    &-tag,
    &-tag2,
    &-tagBlue,
    &-tagRed {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 12px;
      padding: 1px 3px 1px 3px;
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
      border-radius: 4px;
      border: 1px solid #d9ecff;
    }
    &-tag2 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }
    &-tagBlue {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409eff;
    }
    &-tagRed {
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f56c6c;
    }
    &-time,
    &-source,
    &-webSource {
      display: inline-block;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-left: 10px;
    }
    &-source {
      margin-left: 10px;
    }
    &-webSource {
      margin-left: 20px;
    }
    &-website {
      color: rgb(49, 135, 216);
      font-size: 14px;
      margin-top: 5px;
      word-break: break-all;
      margin-right: 10px;
    }
  }
  .tagBox {
    &-tag {
      margin-right: 8px;
    }
  }
  .el-checkbox {
    display: block;
    margin-bottom: 5px;
    flex-direction: row;
  }
}
</style>
